//TODO: this is initial base compoenent. This will trigger the offer details modal later
// https://fastlanefi.atlassian.net/browse/CLF-4801
const DebtHeloanConsolidateDisclaimerCta = ({
  children,
}: {
  children?: React.ReactNode | string;
}) => {
  return (
    <a className="tw-text-link-rest hover:tw-text-link-hover active:tw-text-link-pressed tw-cursor-pointer">
      {children}
    </a>
  );
};

export default DebtHeloanConsolidateDisclaimerCta;
