import { useSelector } from "react-redux";
import { RootState } from "../stores";
import { useStatsigClient } from "@statsig/react-bindings";
import { HELOAN_INELIGIBLE_STATES } from "../helpers/constants";
import { useIsUserAuthenticated } from "./use-is-user-authenticated";

/**
 * List of feature gates used in the project
 */
export const FEATURE_GATES = {
  TAX_SEASON_NOTIFICATION: "1098-tax-season-notification", // permanent
  CASH_OUT_REFINANCE_LOAN_QUOTE: "cash_out_refi_loan_quote",
  ENABLE_MULTIPLE_LOAN_MOCKS: "enable_multiple_loan_mocks",
  ENABLE_MULTIPLE_LOANS: "enable_multiple_loans",
  ENABLE_KUKUN_COST_CALCULATOR: "enable_kukun_cost_calculator",
  ENABLE_TAVANT_DIGITAL_HELOAN: "enable_tavant_digital_heloan",
  ENABLE_LAKEVIEW_HELOAN_LEAD_LINKS: "enable_lakeview_heloan_lead_links",
  ENABLE_GOALS_MODULE_REDESIGN: "enable_goals_module_redesign",
  ENABLE_GOALS_POPUP: "enable_goals_popup",
  ENABLE_OFFER_LAKEVIEW_LOGO: "enable_offer_lakeview_logo",
  ENABLE_VERIFY_EMAIL_UPDATES: "enable_verify_email_updates",
  ENABLE_REMOVE_EQUITY_FROM_OFFERS: "enable_remove_equity_from_offers",
  ENABLE_NEW_LKV_REALTY_REWARDS: "enable_new_lkv_realty_rewards",
  ENABLE_LAKEVIEW_PDPS: "enable_lakeview_pdps",
  ENABLE_LPBF_LOGO_UPDATE: "enable_lpbf_logo_update",
  ENABLE_LAKEVIEW_2_0: "enable_lakeview_2.0",
  ENABLE_KUKUN_HOME_RENOVATION: "enable_kukun_home_renovation",
  ENABLE_LAKEVIEW_VIDEO_BANNER: "enable_lakeview_video_banner",
};

// List of feature gates that should always be enabled
export const ALWAYS_ENABLED_FEATURES = [
  FEATURE_GATES.ENABLE_LAKEVIEW_2_0,
  FEATURE_GATES.ENABLE_LPBF_LOGO_UPDATE,
  FEATURE_GATES.ENABLE_LAKEVIEW_PDPS,
];

export type FeatureGateConfig = Partial<{
  [key in keyof typeof FEATURE_GATES]: boolean;
}>;

type GateConditions = {
  [key: string]: {
    requiresLoadedStatus?: boolean;
  };
};
const GATE_CONDITIONS: GateConditions = {
  [FEATURE_GATES.ENABLE_TAVANT_DIGITAL_HELOAN]: {
    requiresLoadedStatus: true,
  },
};

const isStateIneligible = (state: string | undefined | null) =>
  state ? HELOAN_INELIGIBLE_STATES.includes(state) : false;

const useGateCriteria = (gateName: string) => {
  const { user, status } = useSelector((state: RootState) => state.user);
  const state = user?.address?.state;
  const isUserAuthenticated = useIsUserAuthenticated();

  const gateConditions = GATE_CONDITIONS[gateName];
  if (
    gateConditions?.requiresLoadedStatus &&
    (status === "loading" || status === "failed")
  ) {
    return false;
  }

  const gates = Object.keys(FEATURE_GATES).reduce<FeatureGateConfig>(
    (acc, key) => ({
      ...acc,
      [FEATURE_GATES[key as keyof typeof FEATURE_GATES]]: true,
    }),
    {} as FeatureGateConfig
  );

  const gateCriteria = {
    ...gates,
    [FEATURE_GATES.ENABLE_TAVANT_DIGITAL_HELOAN]: isUserAuthenticated
      ? !isStateIneligible(state)
      : true,
  };

  return gateCriteria[gateName] ?? true;
};

/**
 * Wrapper around the useGate functionality
 * Won't return the value of the feature gate until the SDK is completely initialized
 * Some features are now always enabled without checking with the client
 */
export const useFeatureGate = (gateName: string): boolean => {
  const doesMeetGateCriteria = useGateCriteria(gateName);
  const { client } = useStatsigClient();

  // If the feature is in our always-enabled list, return true immediately
  if (ALWAYS_ENABLED_FEATURES.includes(gateName)) {
    return true;
  }

  if (!doesMeetGateCriteria) return false;

  const value = client.checkGate(gateName);

  return value;
};
