import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "analytics";

import { ReactComponent as LogoLakeviewUnauthenticatedRebrandSVG } from "purple-rain/assets/logo-lakeview-unauthenticated-rebrand.svg";
import { useIsUserAuthenticated } from "../../../hooks/use-is-user-authenticated";
import { ReactComponent as UserSVG } from "purple-rain/icons/user-circle.svg";
import useStandaloneMode from "../../../hooks/use-standalone";
import { translate as t } from "../../../helpers/i18n";
import { useDashboardVersion } from "../../../hooks";
import { pages } from "../../../helpers/pages";
import { RootState } from "../../../stores";

import { AuthenticatedButton, HeaderLoginLink } from "./header-auth";
import { HamburgerMenuV2, ProductsMenuV2 } from "./header-menus-v2";
import { UILogo, UITheme } from "ui";

type Props = {
  displayAuthInfo: boolean;
  showProfileLink?: boolean;
};

const HeaderV2 = ({ displayAuthInfo, showProfileLink = true }: Props) => {
  const user = useSelector((state: RootState) => state.user.user);
  const isUserAuthenticated = useIsUserAuthenticated();
  const shouldRenderStandalone = useStandaloneMode();
  const { mixpanelPageName } = useDashboardVersion();

  const handleLogoClick = () => {
    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      module: "header",
      button: "logo",
      page: mixpanelPageName,
    });
  };

  const logo = isUserAuthenticated ? (
    <UILogo logo="lakeviewFastlane" />
  ) : (
    <LogoLakeviewUnauthenticatedRebrandSVG width={110} />
  );

  if (shouldRenderStandalone)
    return (
      <header className="tw-pt-2 tw-pb-3 md:tw-py-8 tw-bg-slateGray-50">
        <div className="tw-container tw-flex tw-flex-col tw-items-center tw-gap-4">
          {logo}
          <div className="tw-text-deepBlue-950 tw-text-center tw-font-sans">
            {t("layout.header.standalone")}
          </div>
        </div>
      </header>
    );

  return (
    <header className="tw-flex tw-items-center tw-bg-slateGray-50 tw-py-4">
      <div className="tw-container">
        <div className="tw-flex tw-justify-between tw-items-center tw-w-full">
          <div className="tw-flex">
            <HamburgerMenuV2 />
            <Link
              className="tw-p-0"
              to={user ? pages.home : pages.index}
              aria-label={t("layout.header.links.label")}
              onClick={handleLogoClick}
            >
              {logo}
              <span className="tw-hidden">
                {t("accessibility.go_home_button")}
              </span>
            </Link>
          </div>
          <div className="tw-flex-auto">
            <ProductsMenuV2 />
          </div>
          <div className="tw-relative tw-flex tw-items-center tw-shrink-0">
            {user && displayAuthInfo ? (
              <>
                {showProfileLink && (
                  <Link
                    to={pages.profile}
                    className="tw-flex tw-items-center tw-text-blue-900 tw-no-underline tw-mr-0 md:tw-mr-2 lg:tw-mr-4 "
                    data-testid="nav-bar-view-profile-btn"
                  >
                    <UserSVG
                      width={24}
                      height={24}
                      className="tw-mr-0 md:tw-mr-2 tw-text-deepBlue-950"
                    />
                    <span className="tw-hidden md:tw-inline tw-text-deepBlue-950">
                      {t("layout.header.user_greeting", {
                        name: user?.first_name,
                      })}
                    </span>
                    <span className="tw-hidden">
                      {t("accessibility.go_profile_button")}
                    </span>
                  </Link>
                )}
                <div className="tw-hidden md:tw-inline">
                  <AuthenticatedButton
                    theme={UITheme.LAKEVIEW_BLUE}
                    variant="primary"
                  />
                </div>
              </>
            ) : (
              <HeaderLoginLink
                theme={UITheme.LAKEVIEW_BLUE}
                variant="primary"
              />
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderV2;
