import {
  ChangeEventHandler,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";

import { isTestPasswordValid } from "../helpers/constants";
import { translate as t } from "../helpers/i18n";

import { cn, UIIcon } from "ui";

interface PasswordInputProps {
  password: string | number;
  handlePassword: ChangeEventHandler<HTMLInputElement>;
  placeholder: string;
  id: string;
  required?: boolean;
  invalidFeedback?: string;
  pattern?: string;
  minLength?: number;
  maxLength?: number;
  invalidFeedbackClassName?: string;
  isPasswordInput?: boolean;
  dataTestId?: string;
  className?: string;
  isValid?: boolean;
}

interface Length {
  minLength?: number;
  maxLength?: number;
}

interface Pattern {
  pattern?: string;
}

interface Ref {
  ref?: MutableRefObject<null | HTMLInputElement>;
}

const PasswordInputV2 = ({
  required = true,
  invalidFeedbackClassName = "",
  isPasswordInput,
  className,
  isValid,
  ...props
}: PasswordInputProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const {
    password,
    handlePassword,
    placeholder,
    invalidFeedback,
    id,
    dataTestId,
  } = props;
  const passwordRef = useRef(null);

  let length: Length = {};

  if (props?.minLength) length.minLength = props.minLength;
  if (props?.maxLength) length.maxLength = props.maxLength;

  let pattern: Pattern = {};

  if (props?.pattern && !isPasswordInput) pattern.pattern = props?.pattern;

  let ref: Ref = {};

  if (isPasswordInput) ref.ref = passwordRef;

  const handlePasswordVisibility = () =>
    setIsPasswordVisible(!isPasswordVisible);

  useEffect(() => {
    if (passwordRef?.current && isPasswordInput) {
      const passwordInput = passwordRef.current as HTMLInputElement;
      const valid = isTestPasswordValid(password);
      passwordInput.setCustomValidity(valid ? "" : "not valid");
    }
  }, [passwordRef, password, isPasswordInput]);

  return (
    <>
      <div
        className={cn(
          "tw-flex tw-bg-white tw-rounded tw-p-0 tw-px-3 tw-h-14 tw-w-full tw-items-center tw-justify-between tw-border-solid tw-border tw-border-slateGray-625 tw-bg-white hover:tw-border-slateGray-950 focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-deepBlue-950 ",
          !isValid
            ? "!tw-border-coreRed-625 placeholder:tw-text-error tw-text-error"
            : "",
          className
        )}
      >
        <span className="tw-mb-1">
          <UIIcon icon="locked" className="tw-w-4 tw-h-4" />
        </span>
        <input
          type={isPasswordVisible ? "text" : "password"}
          className={cn(
            "tw-bg-transparent tw-border-0 tw-w-full tw-h-full tw-p-0 tw-px-3 tw-outline-none tw-font-sans tw-text-deepBlue-950 placeholder:tw-text-slateGray-625",
            !isValid
              ? "tw-border-coreRed-625 placeholder:tw-text-error tw-text-error"
              : "",
            className
          )}
          id={id}
          placeholder={placeholder}
          value={password}
          onChange={handlePassword}
          required={required}
          ref={passwordRef}
          data-testid={dataTestId}
          {...length}
          {...pattern}
          {...ref}
        />
        <span
          onClick={handlePasswordVisibility}
          className="tw-mb-1"
          aria-label={t("accessibility.toggle_password")}
          role="button"
        >
          <UIIcon
            icon={isPasswordVisible ? "notVisible" : "visible"}
            className="tw-w-4 tw-h-4"
          />
          <span className="tw-hidden">
            {t(
              `accessibility.${isPasswordVisible ? "hide_password" : "show_password"}`
            )}
          </span>
        </span>
      </div>
      {invalidFeedback && !isValid && (
        <div
          className={`tw-text-small tw-mt-2 tw-text-error ${invalidFeedbackClassName}`}
        >
          {invalidFeedback}
        </div>
      )}
    </>
  );
};

export default PasswordInputV2;
