import { ReactNode } from "react";
import { pages } from "../../../helpers/pages";
import { ExternalLink } from "../../../components/external-link";
import DebtHeloanConsolidateDisclaimerCta from "../../../modules/home-value/personalized-offers-v2/debt-heloan-consolidate-offer-disclaimer-cta";

/**
 * Disclosures types used in the footer section
 */
export const DISCLOSURES_TYPES = {
  ESTIMATED_VALUE: "ESTIMATED_VALUE",
  PERSONAL_GOAL_INTEREST_RATE: "PERSONAL_GOAL_INTEREST_RATE",
  PERSONAL_GOAL_INTEREST_RATE_UPDATE: "PERSONAL_GOAL_INTEREST_RATE_UPDATE",
  PERSONAL_GOAL_INTEREST_RATE_POPUP: "PERSONAL_GOAL_INTEREST_RATE_POPUP",
  PERSONAL_GOAL_LEGAL_DISCLAIMER: "PERSONAL_GOAL_LEGAL_DISCLAIMER",
  PERSONAL_GOAL_LEGAL_DISCLAIMER_POPUP: "PERSONAL_GOAL_LEGAL_DISCLAIMER_POPUP",
  PERSONAL_GOAL_REWARDS_LEGAL_DISCLAIMER:
    "PERSONAL_GOAL_REWARDS_LEGAL_DISCLAIMER",
  PERSONAL_GOAL_REWARDS_LEGAL_DISCLAIMER_POPUP:
    "PERSONAL_GOAL_REWARDS_LEGAL_DISCLAIMER_POPUP",
  PERSONAL_GOAL_HOME_EQUITY_DISCLAIMER: "PERSONAL_GOAL_HOME_EQUITY_DISCLAIMER",
  PERSONAL_GOAL_ASSUMABLE_DISCLAIMER: "PERSONAL_GOAL_ASSUMABLE_DISCLAIMER",
  PERSONAL_GOAL_ASSUMABLE_DISCLAIMER_POPUP:
    "PERSONAL_GOAL_ASSUMABLE_DISCLAIMER_POPUP",
  OFFER_CASH_OUT_HELOAN: "OFFER_CASH_OUT_HELOAN",
  OFFER_CASH_OUT_ELIGIBILITY: "OFFER_CASH_OUT_ELIGIBILITY",
  APR_CALCULATIONS: "APR_CALCULATIONS",
  ASSUMABLE_INTEREST_RATES: "ASSUMABLE_INTEREST_RATES",
  ASSUMABLE_REWARDS_PROGRAM: "ASSUMABLE_REWARDS_PROGRAM",
  OFFER_CASH_OUT_ONLY: "OFFER_CASH_OUT_ONLY",
  LHIS_AD: "LHIS_AD",
  PERSONALIZED_OFFER_CONV_RT: "PERSONALIZED_OFFER_CONV_RT",
  PERSONALIZED_OFFER_CONV_RT_POPUP: "PERSONALIZED_OFFER_CONV_RT_POPUP",
  PERSONALIZED_OFFER_FHA: "PERSONALIZED_OFFER_FHA",
  PERSONALIZED_OFFER_FHA_POPUP: "PERSONALIZED_OFFER_FHA_POPUP",
  PERSONALIZED_OFFER_VA_IRRRL: "PERSONALIZED_OFFER_VA_IRRRL",
  PERSONALIZED_OFFER_VA_IRRRL_POPUP: "PERSONALIZED_OFFER_VA_IRRRL_POPUP",
  PERSONALIZED_OFFER_DEBT_HELOAN_CONSOLIDATE:
    "PERSONALIZED_OFFER_DEBT_HELOAN_CONSOLIDATE",
  OFFER_CASH_OUT_ONLY_RE_DESIGN: "OFFER_CASH_OUT_ONLY_RE_DESIGN",
  OFFER_CASH_OUT_ONLY_RE_DESIGN_POPUP: "OFFER_CASH_OUT_ONLY_RE_DESIGN_POPUP",
  OFFER_CASH_OUT_HELOAN_RE_DESIGN: "OFFER_CASH_OUT_HELOAN_RE_DESIGN",
  OFFER_CASH_OUT_HELOAN_RE_DESIGN_POPUP:
    "OFFER_CASH_OUT_HELOAN_RE_DESIGN_POPUP",
  OFFER_DEBT_PAYMENT_BASED_RE_DESIGN: "OFFER_DEBT_PAYMENT_BASED_RE_DESIGN",
  OFFER_DEBT_PAYMENT_BASED_RE_DESIGN_POPUP:
    "OFFER_DEBT_PAYMENT_BASED_RE_DESIGN_POPUP",
  DISCLOSURE_16: "DISCLOSURE_16",
  DISCLOSURE_19: "DISCLOSURE_19",
  DISCLOSURE_20: "DISCLOSURE_20",
  DISCLOSURE_21: "DISCLOSURE_21",
  DISCLOSURE_22: "DISCLOSURE_22",
  DISCLOSURE_23: "DISCLOSURE_23",
  DISCLOSURE_27: "DISCLOSURE_27",
} as const;

export type DisclosureTypeKey = keyof typeof DISCLOSURES_TYPES;

export interface DisclosureContent {
  symbol: string;
  translationKey: string;
  activePath: string | string[];
  href: string;
  components?: {
    [key: string]: ReactNode;
  };
  values?: { [key: string]: string | ReactNode };
}

export type DisclosuresType = Record<DisclosureTypeKey, DisclosureContent>;

export const DISCLOSURES: DisclosuresType = {
  ESTIMATED_VALUE: {
    symbol: "*",
    translationKey: "layout.footer.disclosures.estimated_values",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.ESTIMATED_VALUE,
  },
  PERSONAL_GOAL_INTEREST_RATE: {
    symbol: "‡",
    translationKey: "layout.footer.disclosures.personal_goals_interest_rate",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.PERSONAL_GOAL_INTEREST_RATE,
  },
  PERSONAL_GOAL_INTEREST_RATE_UPDATE: {
    symbol: "‡",
    translationKey:
      "layout.footer.disclosures.personal_goals_interest_rate_update",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.PERSONAL_GOAL_INTEREST_RATE_UPDATE,
    components: {
      link1: <ExternalLink href="https://Bankrate.com/" targetBlank />,
    },
  },
  PERSONAL_GOAL_INTEREST_RATE_POPUP: {
    symbol: "‡",
    translationKey:
      "layout.footer.disclosures.personal_goals_interest_rate_update",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.PERSONAL_GOAL_INTEREST_RATE_POPUP,
    components: {
      link1: <ExternalLink href="https://Bankrate.com/" targetBlank />,
    },
  },
  PERSONAL_GOAL_LEGAL_DISCLAIMER: {
    symbol: "1",
    translationKey: "layout.footer.disclosures.personal_goals_legal_disclaimer",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.PERSONAL_GOAL_LEGAL_DISCLAIMER,
  },
  PERSONAL_GOAL_LEGAL_DISCLAIMER_POPUP: {
    symbol: "1",
    translationKey: "layout.footer.disclosures.personal_goals_legal_disclaimer",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.PERSONAL_GOAL_LEGAL_DISCLAIMER_POPUP,
  },
  PERSONAL_GOAL_REWARDS_LEGAL_DISCLAIMER: {
    symbol: "15",
    translationKey:
      "layout.footer.disclosures.personal_goals_rewards_legal_disclaimer",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.PERSONAL_GOAL_REWARDS_LEGAL_DISCLAIMER,
  },
  PERSONAL_GOAL_REWARDS_LEGAL_DISCLAIMER_POPUP: {
    symbol: "15",
    translationKey:
      "layout.footer.disclosures.personal_goals_rewards_legal_disclaimer",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.PERSONAL_GOAL_REWARDS_LEGAL_DISCLAIMER_POPUP,
  },
  PERSONAL_GOAL_HOME_EQUITY_DISCLAIMER: {
    symbol: "14",
    translationKey:
      "layout.footer.disclosures.personal_goals_home_equity_disclaimer",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.PERSONAL_GOAL_HOME_EQUITY_DISCLAIMER,
  },
  PERSONAL_GOAL_ASSUMABLE_DISCLAIMER: {
    symbol: "17",
    translationKey:
      "layout.footer.disclosures.personal_goals_assumable_disclaimer",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.PERSONAL_GOAL_ASSUMABLE_DISCLAIMER,
  },
  PERSONAL_GOAL_ASSUMABLE_DISCLAIMER_POPUP: {
    symbol: "17",
    translationKey:
      "layout.footer.disclosures.personal_goals_assumable_disclaimer",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.PERSONAL_GOAL_ASSUMABLE_DISCLAIMER_POPUP,
  },
  OFFER_CASH_OUT_HELOAN: {
    symbol: "2",
    translationKey: "layout.footer.disclosures.offer_cash_out_heloan",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.OFFER_CASH_OUT_HELOAN,
  },
  OFFER_CASH_OUT_HELOAN_RE_DESIGN: {
    symbol: "2",
    translationKey: "layout.footer.disclosures.offer_cash_out_heloan_re_design",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.OFFER_CASH_OUT_HELOAN_RE_DESIGN,
  },
  OFFER_CASH_OUT_HELOAN_RE_DESIGN_POPUP: {
    symbol: "2",
    translationKey: "layout.footer.disclosures.offer_cash_out_heloan_re_design",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.OFFER_CASH_OUT_HELOAN_RE_DESIGN_POPUP,
  },
  OFFER_CASH_OUT_ONLY: {
    symbol: "8",
    translationKey: "layout.footer.disclosures.offer_cash_out_only",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.OFFER_CASH_OUT_ONLY,
  },
  OFFER_CASH_OUT_ONLY_RE_DESIGN: {
    symbol: "3",
    translationKey: "layout.footer.disclosures.offer_cash_out_only_re_design",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.OFFER_CASH_OUT_ONLY_RE_DESIGN,
  },
  OFFER_CASH_OUT_ONLY_RE_DESIGN_POPUP: {
    symbol: "3",
    translationKey:
      "layout.footer.disclosures.offer_cash_out_only_re_design_popup",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.OFFER_CASH_OUT_ONLY_RE_DESIGN_POPUP,
  },
  OFFER_CASH_OUT_ELIGIBILITY: {
    symbol: "8",
    translationKey: "layout.footer.disclosures.offer_debt.item_08",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.OFFER_CASH_OUT_ELIGIBILITY,
  },
  OFFER_DEBT_PAYMENT_BASED_RE_DESIGN: {
    symbol: "4",
    translationKey: "layout.footer.disclosures.offer_debt.item_04_re_design",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.OFFER_DEBT_PAYMENT_BASED_RE_DESIGN,
  },
  OFFER_DEBT_PAYMENT_BASED_RE_DESIGN_POPUP: {
    symbol: "4",
    translationKey:
      "layout.footer.disclosures.offer_debt.item_04_re_design_popup",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.OFFER_DEBT_PAYMENT_BASED_RE_DESIGN_POPUP,
  },
  APR_CALCULATIONS: {
    symbol: "5",
    translationKey: "layout.footer.disclosures.offer_debt.item_05",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.APR_CALCULATIONS,
  },
  PERSONALIZED_OFFER_CONV_RT: {
    symbol: "5",
    translationKey: "layout.footer.disclosures.personalized_offers.item_05",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.PERSONALIZED_OFFER_CONV_RT,
  },
  PERSONALIZED_OFFER_CONV_RT_POPUP: {
    symbol: "5",
    translationKey: "layout.footer.disclosures.personalized_offers.item_05",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.PERSONALIZED_OFFER_CONV_RT_POPUP,
  },
  PERSONALIZED_OFFER_FHA: {
    symbol: "5a",
    translationKey: "layout.footer.disclosures.personalized_offers.item_05a",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.PERSONALIZED_OFFER_FHA,
  },
  PERSONALIZED_OFFER_FHA_POPUP: {
    symbol: "5a",
    translationKey: "layout.footer.disclosures.personalized_offers.item_05a",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.PERSONALIZED_OFFER_FHA_POPUP,
  },
  PERSONALIZED_OFFER_VA_IRRRL: {
    symbol: "5b",
    translationKey: "layout.footer.disclosures.personalized_offers.item_05b",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.PERSONALIZED_OFFER_VA_IRRRL,
  },
  PERSONALIZED_OFFER_VA_IRRRL_POPUP: {
    symbol: "5b",
    translationKey: "layout.footer.disclosures.personalized_offers.item_05b",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.PERSONALIZED_OFFER_VA_IRRRL_POPUP,
  },
  PERSONALIZED_OFFER_DEBT_HELOAN_CONSOLIDATE: {
    symbol: "24",
    translationKey: "layout.footer.disclosures.personalized_offers.item_24",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.PERSONALIZED_OFFER_DEBT_HELOAN_CONSOLIDATE,
    components: { link1: <DebtHeloanConsolidateDisclaimerCta /> },
  },
  ASSUMABLE_INTEREST_RATES: {
    symbol: "6",
    translationKey:
      "layout.footer.disclosures.offer_assumable_mortgage.item_06",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.ASSUMABLE_INTEREST_RATES,
  },
  ASSUMABLE_REWARDS_PROGRAM: {
    symbol: "7",
    translationKey:
      "layout.footer.disclosures.offer_assumable_mortgage.item_07",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.ASSUMABLE_REWARDS_PROGRAM,
  },
  LHIS_AD: {
    symbol: "9",
    translationKey: "layout.footer.disclosures.lhis.item_09",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.LHIS_AD,
    components: {
      link1: (
        <ExternalLink
          href="https://www.lakeviewinsurancesolutions.com/carriers/"
          targetBlank
        />
      ),
      link2: (
        <ExternalLink
          href="https://www.lakeviewinsurancesolutions.com/agency-licenses/"
          targetBlank
        />
      ),
      link3: (
        <ExternalLink
          href="https://www.lakeviewinsurancesolutions.com/"
          targetBlank
        />
      ),
    },
  },
  DISCLOSURE_16: {
    symbol: "16",
    translationKey: "layout.footer.disclosures.item_16",
    activePath: pages.home,
    href: DISCLOSURES_TYPES.DISCLOSURE_16,
    components: {
      sup: <sup />,
    },
  },
  DISCLOSURE_19: {
    symbol: "19",
    translationKey: "layout.footer.disclosures.item_19",
    activePath: pages.products.cashOutRefinance,
    href: DISCLOSURES_TYPES.DISCLOSURE_19,
  },
  DISCLOSURE_20: {
    symbol: "20",
    translationKey: "layout.footer.disclosures.item_20",
    activePath: pages.products.getAMortgage,
    href: DISCLOSURES_TYPES.DISCLOSURE_20,
  },
  DISCLOSURE_21: {
    symbol: "21",
    translationKey: "layout.footer.disclosures.item_21",
    activePath: pages.products.getAMortgage,
    href: DISCLOSURES_TYPES.DISCLOSURE_21,
  },
  DISCLOSURE_22: {
    symbol: "22",
    translationKey: "layout.footer.disclosures.item_22",
    activePath: pages.products.getAMortgage,
    href: DISCLOSURES_TYPES.DISCLOSURE_22,
  },
  DISCLOSURE_23: {
    symbol: "23",
    translationKey: "layout.footer.disclosures.item_23",
    activePath: [
      pages.products.getAMortgage,
      pages.products.rateTermRefinance,
      pages.products.cashOutRefinance,
    ],
    href: DISCLOSURES_TYPES.DISCLOSURE_23,
  },
  DISCLOSURE_27: {
    symbol: "27",
    translationKey: "layout.footer.disclosures.item_27",
    activePath: [
      pages.home,
      pages.resourceCenter,
      pages.products.cashOutRefinance,
      pages.products.rateTermRefinance,
    ],
    href: DISCLOSURES_TYPES.DISCLOSURE_27,
    components: {
      link1: <ExternalLink href="https://www.embs.com/" targetBlank />,
    },
  },
} as const;
