import { ReactComponent as EqualHousingIcon } from "purple-rain/assets/icons/equal-housing.svg";
import { Trans } from "react-i18next";
import { ExternalLink } from "../../../components/external-link";
import { CONSUMER_ACCESS_LINK } from "../../../helpers/constants";
import { pages } from "../../../helpers/pages";
import { useLocation } from "react-router-dom";
import { cn, GridCol, GridContainer } from "ui";

const PAGES_WITHOUT_FOOTER = [pages.digitalAccountCreation];

interface Props {
  className?: string;
  hasGridXPadding?: boolean;
  isContentVerticallyCentered?: boolean;
}

const NMLSFooterV2 = ({
  className = "",
  hasGridXPadding,
  isContentVerticallyCentered,
}: Props) => {
  const { pathname } = useLocation();

  if (PAGES_WITHOUT_FOOTER.some((page) => pathname.startsWith(page))) return;

  return (
    <section className={className}>
      <GridContainer containerClassName={cn(!hasGridXPadding && "tw-px-0")}>
        <GridCol
          className={cn(
            "tw-flex tw-justify-between tw-gap-4",
            isContentVerticallyCentered ? "tw-items-center" : "tw-items-end"
          )}
        >
          <div className="tw-text-small tw-text-slateGray-950">
            <Trans
              i18nKey="cash_out_loan_quote.footer"
              components={{
                link1: (
                  <ExternalLink
                    module="unauthenticated-dashboard-lakeview-blue"
                    button="lakeview"
                    href={CONSUMER_ACCESS_LINK}
                    targetBlank
                    className="tw-text-deepBlue-625"
                  />
                ),
              }}
            />
          </div>
          <EqualHousingIcon className="tw-text-slateGray-625" />
        </GridCol>
      </GridContainer>
    </section>
  );
};

export default NMLSFooterV2;
